import {
  Column,
  SubSectionHeader,
  Text,
  HeaderMedium,
} from "../page-components/MobileApp/styles";
import { SiteWrapper } from "../components/SiteWrapper";
import { ACTIVE_PAGE } from "../components/nav/utils";
import React from "react";
import useWindowDimensions from "../utils/windowSize";
import Seo from "../components/seo";
import EmailSignUp from "../page-components/LandingPage/EmailSignUp";
import {
  EmailSignUpWrapper,
  FullWidthGraphic,
  FullWidthGraphic2,
  FullWidthGraphicContainer,
  Graphic1,
  Graphic1Wrapper,
  HeaderContainer,
  MainContainer,
  Row,
  Row1,
  SignUpSection,
  SkeletonFullWidthGraphicContainer,
  SkeletonHeaderCont,
  SkeletonMainContainer,
  SkeletonRow1,
  Subheader,
  TextRow,
  WebColumn,
  WebGraphic,
  WebGraphic2,
  WebGraphicWrapper,
  WebHeaderLarge,
  WebTextContainer,
  WhiteSubSectionHeader,
} from "../page-components/WebApp/styles";
import { Skeleton } from "@mui/material";

export default function WebAppPage() {
  const { width } = useWindowDimensions();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => setLoading(false), 200);
  }, []);

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.WEBAPP}>
      <Seo
        title="Web App"
        description="Get a precise, as-built building model of real estate quickly. We deliver accurate point cloud data, 2D CAD, and 3D BIM at competitive prices."
      />
      <header className="site__header"></header>
      {loading ? (
        <SkeletonMainContainer variant="rectangular" />
      ) : (
        <MainContainer>
          {loading ? (
            <SkeletonRow1 variant="rectangular" />
          ) : (
            <Row1>
              {loading ? (
                <SkeletonHeaderCont variant="text" animation="pulse" />
              ) : (
                <HeaderContainer>
                  {width && width > 980 ? (
                    <>
                      <HeaderMedium>Effortless</HeaderMedium>
                      <WebHeaderLarge>Field</WebHeaderLarge>
                      <HeaderMedium>Management</HeaderMedium>
                    </>
                  ) : (
                    <>
                      <HeaderMedium>Effortless</HeaderMedium>
                      <HeaderMedium>Field Management</HeaderMedium>
                    </>
                  )}
                </HeaderContainer>
              )}
              <Graphic1Wrapper>
                <Graphic1
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FWebApp%2FLaptop%20Final%20Mock%20Up%20v2.png?alt=media&token=4427a926-4dea-4598-a8e5-65d51438ef4c"
                  }
                  alt="download on app store"
                />
              </Graphic1Wrapper>
            </Row1>
          )}
          {loading ? (
            <SkeletonFullWidthGraphicContainer variant="rectangular" />
          ) : (
            <FullWidthGraphicContainer>
              <FullWidthGraphic
                src={
                  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FWebApp%2F5x%20Web%20Screens%20Computer%20mockup.png?alt=media&token=c69c7ab0-8834-4f3b-bba5-bf4dd185386a"
                }
              />
              <SignUpSection>
                <Subheader>Sign Up Today!</Subheader>
                <EmailSignUpWrapper>
                  <EmailSignUp />
                </EmailSignUpWrapper>
              </SignUpSection>
            </FullWidthGraphicContainer>
          )}

          <TextRow>
            <WebColumn>
              <SubSectionHeader>
                Documentation at your fingertips
              </SubSectionHeader>
              <Text>
                Robotic Imaging, a leader in digital field management and
                documentation, proudly presents its comprehensive web platform.
                Our innovative platform revolutionizes field operations with its
                extensive tools for project management, documentation, and
                collaboration – all accessible from your web browser.
              </Text>
              <SubSectionHeader>Centralized Field Management</SubSectionHeader>
              <Text>
                Our web platform offers a user-friendly interface and robust
                tools, enabling professionals to efficiently manage their
                projects. Here's how Robotic Imaging enhances your workflow:
              </Text>
              <div style={{ paddingLeft: "20px" }}>
                <WhiteSubSectionHeader>
                  Real-time project status
                </WhiteSubSectionHeader>
                <Text>
                  Monitor the status of all your projects in one place. Stay
                  up-to-date with real-time updates and track progress
                  seamlessly.
                </Text>
                <WhiteSubSectionHeader>
                  schedule new scans
                </WhiteSubSectionHeader>
                <Text>
                  Easily schedule new scans with just a few clicks. Plan and
                  organize your field visits efficiently to keep your projects
                  on track.
                </Text>
                <WhiteSubSectionHeader>
                  Access all documentation
                </WhiteSubSectionHeader>
                <Text>
                  View your Matterport scans and final project documentation
                  effortlessly. Our platform ensures you have quick access to
                  all essential files
                </Text>
                <WhiteSubSectionHeader>
                  Customizable Documentation Forms
                </WhiteSubSectionHeader>
                <Text>
                  Create and utilize customizable forms to better document your
                  projects. Tailor your documentation process to meet your
                  specific needs and ensure thorough record-keeping.
                </Text>
              </div>
            </WebColumn>
            <WebColumn>
              <WebGraphicWrapper>
                <WebGraphic
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FWebApp%2FScreenshot%202024-07-17%20at%201.04.23%E2%80%AFPM.png?alt=media&token=eac2f5e9-76e6-4a85-a883-226cc16bc72e"
                  }
                  alt="example graphic"
                />
              </WebGraphicWrapper>
              <WebTextContainer>
                <SubSectionHeader>Enhanced Collaboration</SubSectionHeader>
                <Text>
                  Stay connected with your team and stakeholders. Share project
                  details, scans, and documentation instantly, fostering better
                  communication and collaboration
                </Text>
              </WebTextContainer>
            </WebColumn>
          </TextRow>
          <Row>
            <Column>
              <WebGraphic
                src={
                  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FWebApp%2FLaptop%20Final%20Mock%20Up.png?alt=media&token=5d9d8392-ea33-4b22-9d28-3bc6305f2c2c"
                }
                alt="example graphic"
              />
            </Column>
            <Column>
              <SubSectionHeader>Why choose robotic imaging?</SubSectionHeader>
              <Text>
                Opting for Robotic Imaging's web platform means choosing
                unparalleled convenience, accuracy, and efficiency in managing
                field operations. Our platform is designed to:
                <ul style={{ maxWidth: "90%", paddingLeft: "2vw" }}>
                  <li>Keep all project-related files organized</li>
                  <li>Enhance productivity by streamlining workflows</li>
                  <li>Improve the accuracy of project documentation</li>
                  <li>Boost collaboration among team members</li>
                </ul>
              </Text>
            </Column>
          </Row>
          <FullWidthGraphic2
            src={
              "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FWebApp%2FMAC%20MOCKUP%20v2.png?alt=media&token=a0fd7d6a-9be3-45b0-9c46-0930bbac44fa"
            }
            alt="full width graphic"
          />
        </MainContainer>
      )}
    </SiteWrapper>
  );
}
